<template>
    <v-container fill-height :class="$vuetify.breakpoint.xsOnly ? 'onboard-bg-sm' : 'onboard-bg'">
        <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6">

                <div v-if="fetching">
                    <v-row>
                        <v-col cols="12" justify="center" align="center" class="mt-5">
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                        </v-col>

                    </v-row>
                </div>

                <div v-else>

                    <div v-if="sub_reference_id != ''">
                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>
                        <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Premium Membership
                        </div>
                        <div class="py-16">

                            <div class="text-subtitle-2">Your {{ subscription_period }} Subscription is <span
                                    class="font-weight-bold" :class="status == 'ACTIVE' ? 'success--text' : 'orange--text'"> {{ status }}</span></div>
                            <div v-if="next_billing != '' && status == 'ACTIVE'" class="text-caption pt-4">Next billing on <span
                                    class="font-weight-bold">{{ next_billing }}</span></div>
                        </div>
                        <div v-if="sub_reference_id != '' && status == 'ACTIVE'">
                            <v-btn color="primary" @click="cancelConfirm = true" :disabled="cancelling" :loading="cancelling">
                                Cancel Subscription
                            </v-btn>
                        </div>
                        <div class="text-center my-8" v-if="!this.$store.getters.isPremium">
                        <v-btn class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                            PREMIUM</v-btn>
                    </div>

                    </div>

                </div>

                

                <div v-else>

                    <div class="text-center">
                        <v-icon class="golden-chip" style="border-radius:40px; padding:10px" color="#d4af37"
                            large>mdi-crown</v-icon>
                        <div class="text-overline pt-2 premium-title" style="font-size:.9em !important;">Unlock Premium
                            Benefits </div>
                    </div>

                    <div class="pa-4 mt-4 mb-8" style="background-color: rgba(0, 31, 63, 0.05); border-radius:10px;">
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Seamless communication</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Send/Receive unlimited messages</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Direct connect credits</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4 pb-0">Stand out with our Premium tag</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="1" class="pb-0">
                                <v-icon color="#d4af37">mdi-crown-circle</v-icon>
                            </v-col>
                            <v-col cols="11" class="pl-4">Get Featured on Social Media</v-col>
                        </v-row>
                    </div>

                    <div class="text-center my-8">
                        <v-btn class="golden-chip" @click="showPremium"><v-icon left>mdi-crown</v-icon> UPGRADE TO
                            PREMIUM</v-btn>
                    </div>

                </div>


                </div>

                
            </v-col>
        </v-row>

    <v-dialog v-model="cancelConfirm" max-width="400">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <p>
                    Are you sure you want to cancel your Premium Membership subscription?
                </p>
            </v-card-text>

            <v-card-actions>
                    <v-btn text color="primary" @click="cancelConfirm = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="cancelSubscription" :loading="cancelling" :disabled="cancelling">Yes, Cancel Now</v-btn>
                </v-card-actions>

        </v-card>
    </v-dialog>

        <v-snackbar v-model="snackbar">
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>
    
<script>
import axios from "axios";
import Bugsnag from '@bugsnag/js'
export default {
    name: 'Billing',

    data() {
        return {

            snackbar: false,
            snackbar_text: '',
            next_billing: '',
            status: '',
            subscription_period: '',
            fetching: false,
            cancelling: false,
            sub_reference_id: '',
            cancelConfirm: false,


        }
    },

    mounted() {
        this.getSubscriptionDetails();
    },

    methods: {
        getSubscriptionDetails() {

            this.fetching = true;
            axios.get('/v1/subscription-cf/get').then((resp) => {
                //console.log(resp.data);

                this.sub_reference_id = resp.data.sub_reference_id;
                this.status = resp.data.status;
                this.subscription_period = resp.data.subscription_period;
                this.next_billing = resp.data.next_billing;

                this.fetching = false;
            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.fetching = false;
            });


        },
        cancelSubscription() {
            this.cancelling = true;
            axios.post('/v1/subscription-cf/cancel/' + this.sub_reference_id).then((resp) => {
                //console.log(resp.data);
                this.getSubscriptionDetails();
                this.cancelling = false;
                this.cancelConfirm = false;
            }).catch(err => {
                //console.log(err.response);
                Bugsnag.notify(err)
                this.cancelling = false;
                this.cancelConfirm = false;
            });

        },

        showPremium() {
            this.$store.dispatch('showPremiumPop', true);
        },
    }
}
</script>
    